import { getAuth, signInWithEmailAndPassword, createUserWithEmailAndPassword, signOut, onAuthStateChanged } from "firebase/auth";
import { getDatabase, ref, set, get, query, orderByChild, equalTo } from "firebase/database";
import router from "@/router";

//let timer;

const state = {
  userId: null,
  token: null,
  userName: null,
  userRole: null,
};

const mutations = {
  setUser(state, payload) {
    state.userId = payload.userId;
    state.token = payload.token;
    state.userName = payload.userName;
    state.userRole = payload.userRole || state.userRole;
  },
  setUserRole(state, role) {
    state.userRole = role;
  },
};

const actions = {
  async getUserByEmail(email) {
    const db = getDatabase();
    const usersRef = ref(db, "users");
    const emailQuery = query(usersRef, orderByChild("email"), equalTo(email));

    try {
      const snapshot = await get(emailQuery);

      if (snapshot.exists()) {
        const users = snapshot.val();
        const userId = Object.keys(users)[0]; 
        return { userId, ...users[userId] };
      } else {
        console.error("Kein Benutzer mit dieser E-Mail gefunden.");
        return null;
      }
    } catch (error) {
      console.error("Fehler beim Abrufen des Benutzers:", error);
      throw error;
    }
  },

  async auth(context, payload) {
    const auth = getAuth();
    let userCredential;

    try {
      if (payload.mode === "signin") {
        userCredential = await signInWithEmailAndPassword(auth, payload.email, payload.password);
      } else if (payload.mode === "signup") {
        userCredential = await createUserWithEmailAndPassword(auth, payload.email, payload.password);
      } else {
        throw new Error("Ungültiger Authentifizierungsmodus");
      }

      const user = userCredential.user;
      const token = await user.getIdToken();

      localStorage.setItem("token", token);
      localStorage.setItem("userId", user.uid);
      localStorage.setItem("userName", user.email);

      context.commit("setUser", {
        userId: user.uid,
        token,
        userName: user.email,
      });

      // Realtime Database: Benutzerrolle abrufen oder setzen
      const db = getDatabase();
      const userRef = ref(db, `users/${user.uid}`);
      const snapshot = await get(userRef);

      if (!snapshot.exists()) {
        await set(userRef, { email: user.email, role: "user" });
        context.commit("setUserRole", "user");
      } else {
        context.commit("setUserRole", snapshot.val().role);
      }
    } catch (error) {
      console.error("Fehler bei der Authentifizierung:", error);
      throw new Error(error.message || "Unbekannter Fehler");
    }
  },

  async autoSignin(context) {
    const auth = getAuth();

    console.log("autoSignin");
    console.log("auth", auth);
    console.log("context", context);

    onAuthStateChanged(auth, async (user) => {
      console.log("onAuthStateChanged", user);
      if (user) {
        const token = await user.getIdToken();
        localStorage.setItem("token", token);
        localStorage.setItem("userId", user.uid);
        localStorage.setItem("userName", user.email);

        console.log("Benutzer angemeldet:", user);

        context.commit("setUser", {
          userId: user.uid,
          token,
          userName: user.email,
        });

        

        // Realtime Database: Benutzerrolle abrufen
        const db = getDatabase();
        const userRef = ref(db, `users/${user.uid}`);
        const snapshot = await get(userRef);

        if (snapshot.exists()) {
          context.commit("setUserRole", snapshot.val().role);
        }
      } else {
        context.dispatch("signout");
      }
    });
  },

  async signout(context) {
    const auth = getAuth();

    try {
      await signOut(auth);
      localStorage.removeItem("token");
      localStorage.removeItem("userId");
      localStorage.removeItem("userName");

      context.commit("setUser", {
        token: null,
        userId: null,
        userName: null,
      });

      router.push("/");
    } catch (error) {
      console.error("Fehler beim Abmelden:", error);
    }
  },




  signup(context, payload) {
    const signupDO = {
      ...payload,
      mode: "signup",
    };
    return context.dispatch("auth", signupDO);
  },
  signin(context, payload) {
    const signinDO = {
      ...payload,
      mode: "signin",
    };
    return context.dispatch("auth", signinDO);
  },


};

const getters = {
  isAuthenticated: (state) => !!state.token,
  token: (state) => state.token,
  refreshToken: (state) => state.refreshToken,
  userName: (state) => state.userName,
  userRole: (state) => state.userRole, // Getter für die Rolle
};

const authModule = {
  state,
  mutations,
  actions,
  getters,
};

export default authModule;
