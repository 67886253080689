import { createApp } from "vue";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import App from "./App.vue";
import store from "./store"; // Importieren Sie den Store hier
import router from "./router/index.js";
import '../node_modules/bootstrap/dist/js/bootstrap.min.js';

const auth = getAuth();
let app;

onAuthStateChanged(auth, (user) => {
    if (user) {
      store.dispatch("autoSignin"); // aktualisiert den Store
    }
    if (!app) {
      app = createApp(App);
      app.use(store);
      app.use(router);
      app.mount("#app");
    }
  });
/* const app = createApp(App);

app.directive("focus", {
    mounted(el) {
        el.focus();
    },
    });
app.use(store);
app.use(router);
app.mount("#app");
 */