import axios from "axios";
import { JUNKER_API_KEY } from "../../../config/junker.js";
import { apiConfig } from "@/config/firebaseConfig.js";

const state = {
  lexofficeContacts: null,
  loadingLexoffice: false,
  sendingLexoffice: false,
  sendingOrderConfirmation: false,
  orderConfirmationError: null,
};

const mutations = {
  setLexofficeContacts(state, lexofficeContacts) {
    state.lexofficeContacts = lexofficeContacts;
  },
  setLoadingLexoffice(state, loading) {
    state.loadingLexoffice = loading;
  },
  setSendingLexoffice(state, loading) {
    state.sendingLexoffice = loading;
  },
  // Mutationen für den Sendevorgang
  startSendingOrderConfirmation(state) {
    state.sendingOrderConfirmation = true;
    state.orderConfirmationError = null;
  },
  finishSendingOrderConfirmation(state, error = null) {
    state.sendingOrderConfirmation = false;
    state.orderConfirmationError = error;
  },
};

const actions = {
  fetchLexofficeContacts({ commit }) {
    commit("setLoadingLexoffice", true);
    axios
      .get(`${apiConfig.apiUrl}/api/lexofficekontakte?apiKey=${JUNKER_API_KEY}`)
      .then((response) => {
        commit("setLexofficeContacts", response.data);
        commit("setLoadingLexoffice", false);
        return response;
      })
      .catch((error) => {
        console.log("Es gab ein Problem mit dem Abruf der Daten:", error);
        commit("setLoadingLexoffice", false);
      });
  },




  async fetchInvoiceDetails({ commit, dispatch }, belegung) {
    commit("setLoadingLexoffice", true);
    const invoiceId = belegung.lexoffice?.rechnung?.data?.id;
    console.log("Belegung", belegung);

    if (!invoiceId) {
      console.error("Rechnungs-ID nicht gefunden");
      commit("setLoadingLexoffice", false);
      return;
    }

    try {
      const response = await axios.post(
        `${apiConfig.apiUrl}/api/lexoffice/rechnung/details?apiKey=${JUNKER_API_KEY}`,
        { "invoiceId": invoiceId }
      );

      const invoiceDetails = response.data;
      console.log("RESPONSE", response);

      // Speichere die Rechnungsdetails in der Belegung
      await dispatch("updateBelegung", {
        id: belegung.id,
        lexoffice: {
          ...belegung.lexoffice,
          rechnung: {
            ...belegung.lexoffice.rechnung,
            data: {
              ...belegung.lexoffice.rechnung.data,
              details: invoiceDetails,
            },
          },
        },
      });

      commit("setLoadingLexoffice", false);
    } catch (error) {
      console.error("Fehler beim Abrufen der Rechnungsdetails:", error);
      commit("setLoadingLexoffice", false);
    }
  },

  async fetchCreditNoteDetails({ commit, dispatch }, belegung) {
    commit("setLoadingLexoffice", true);
    const creditNoteId = belegung.lexoffice?.gutschrift?.data?.id;
    console.log("Belegung (Gutschrift)", belegung.lexoffice?.gutschrift?.data?.id);

    if (!creditNoteId) {
      console.error("Gutschrifts-ID nicht gefunden");
      commit("setLoadingLexoffice", false);
      return;
    }

    try {
      const response = await axios.post(
        `${apiConfig.apiUrl}/api/lexoffice/gutschrift/details?apiKey=${JUNKER_API_KEY}`,
        { "creditNoteId": creditNoteId }
      );

      const creditNoteDetails = response.data;
      console.log("RESPONSE GUTSCHRIFT", creditNoteDetails);

      // Speichere die Gutschriftsdetails in der Belegung
      await dispatch("updateBelegung", {
        id: belegung.id,
        lexoffice: {
          ...belegung.lexoffice,
          gutschrift: {
            ...belegung.lexoffice.gutschrift,
            data: {
              ...belegung.lexoffice.gutschrift.data,
              details: creditNoteDetails,
            },
          },
        },
      });

      commit("setLoadingLexoffice", false);
    } catch (error) {
      console.error("Fehler beim Abrufen der Gutschriftsdetails:", error);
      commit("setLoadingLexoffice", false);
    }
  },

  async sendOrderConfirmation({ commit }, orderConfirmation) {
    commit("setSendingLexoffice", true);

    try {
      const response = await axios.post(
        `${apiConfig.apiUrl}/api/lexofficerechnung?apiKey=${JUNKER_API_KEY}`,
        orderConfirmation
      );
      commit("setSendingLexoffice", false);
      console.log("Auftragsbestätigung erfolgreich gesendet:", response);

      // Erfolgsobjekt zurückgeben
      return { success: true, data: response.data };
    } catch (error) {
      console.error(
        "Es gab ein Problem beim Senden der Rechnung:",
        error
      );
      commit("setSendingLexoffice", false);

      // Fehlerobjekt zurückgeben
      return { success: false, error: error };
    }
  },

  async sendCreditnote({ commit }, orderConfirmation) {
    commit("setSendingLexoffice", true);

    try {
      const response = await axios.post(
        `${apiConfig.apiUrl}/api/lexofficegutschrift?apiKey=${JUNKER_API_KEY}`,
        orderConfirmation
      );
      commit("setSendingLexoffice", false);
      console.log("Gutschrift erfolgreich gesendet:", response);

      // Erfolgsobjekt zurückgeben
      return { success: true, data: response.data };
    } catch (error) {
      console.error(
        "Es gab ein Problem beim Senden der Gutschrift:",
        error
      );
      commit("setSendingLexoffice", false);

      // Fehlerobjekt zurückgeben
      return { success: false, error: error };
    }
  },


};

const getters = {
  getLexofficeContacts: (state) => {
    return state.lexofficeContacts;
  },
  isLoadingLexoffice: (state) => {
    return state.loadingLexoffice;
  },
  isSendingLexoffice: (state) => {
    return state.sendingLexoffice;
  },
  // Getter für den Sendevorgang
  isSendingOrderConfirmation: (state) => {
    return state.sendingOrderConfirmation;
  },
  getOrderConfirmationError: (state) => {
    return state.orderConfirmationError;
  },
};

const lexofficeModule = {
  state,
  mutations,
  actions,
  getters,
};
export default lexofficeModule;
